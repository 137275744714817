.m-availability {
  cursor: pointer;

  &--not-clickable {
    cursor: revert;
  }

  &--section-info {
    display: inline-block;
    font-size: unquote("max(#{$font-size-base}, 1rem)"); // hack for scss failing build
  }

  &__label {
    margin-right: map-get($spacers, 1);
    scroll-margin-block-start: map-get($spacers, 1);
    letter-spacing: normal;

    &--available {
      display: none;
      position: relative;
      top: 0.0625em;
      padding: 0;
      font-size: 140%;
      color: $label-success-bg;
      color: var(--text-success, #{$state-success-text});
      line-height: 0 !important; // remove the extra vertical space of the icon
    }

    &--unavailable {
      display: inline;
      color: $label-danger-bg;
      color: var(--text-danger, #{$state-danger-text});
      border: 1px solid $label-danger-bg;
      border-color: var(--text-danger, #{$state-danger-text});
    }

    &--maybe-available {
      display: inline;
      color: $label-warning-bg;
      color: var(--text-warning, #{$state-warning-text});
      border: 1px solid $label-warning-bg;
      border-color: var(--text-warning, #{$state-warning-text});
    }

    &--section-info {
      display: inline-block;
      padding: map-get($spacers, 1);
      color: var(--menu-section-title-bg-color, var(--color-body-bg));
      background-color: var(--menu-section-title-color, var(--color-body-text));
      text-transform: none;
    }
  }

  .popover {
    width: 700px;
    max-width: 100%;

    @media screen and (min-width: $grid-float-breakpoint) {
      max-width: 100vw;
    }
  }

  &__popover {
    &__title {
      &--available {
        display: none;
        font-size: 14px;
        font-weight: 600;
      }

      &--unavailable {
        display: inline;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &.is-available {
    .m-availability__label--available {
      display: inline;
    }

    .m-availability__label--unavailable {
      display: none;
    }

    .m-availability__popover__title--available {
      display: inline;
    }

    .m-availability__popover__title--unavailable {
      display: none;
    }
  }

  &__sheet {
    margin-top: 16px;

    &__header {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #e2e2e2;
      font-weight: 600;
      text-align: center;

      @media screen and (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 0;
        float: left;
        width: 50%;
        text-align: left;
      }
    }

    &__data {
      padding-bottom: 10px;
      font-weight: 600;
      text-align: center;

      @media screen and (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 0;
        float: left;
        width: 50%;
        border-bottom: 1px solid #e2e2e2;
      }
    }
  }
}
